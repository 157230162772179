import { useMemo, useState } from "react";
import { renderToStaticMarkup } from "react-dom/server";
import { useNavigate } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { createColumnHelper } from "@tanstack/react-table";
import Skeleton from "react-loading-skeleton";
import { Tooltip } from "react-tooltip";
import { DateTime } from "luxon";
import { faBuilding, faBuildingUser } from "@fortawesome/pro-regular-svg-icons";
import Popover from "core/components/Popover.js";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis } from "@fortawesome/pro-light-svg-icons";

import Table, {
  SearchableTableCell,
  TextWithIconTableCell,
} from "core/components/Table";
import Pill from "core/components/Pill";
import Link from "core/components/Link";
import DeactivateOrganizationModal from "./DeactivateOrgModal";
import ActivateOrganizationModal from "./ActivateOrgModal";
import useAuthenticatedCall from "core/hooks/useAuthenticatedCall";
import {
  getOrganizationDeployment,
  getOrganizationOnboarding,
} from "./actions";
import Typography from "core/components/Typography";
import { ThemeProvider } from "styled-components";
import theme from "theme";
import useNavigation from "core/hooks/useNavigation.js";

const dateTimeFormat = "FF";
const durationFormat = "m'm ' s's'";

const ONBOARDING_LABELS = {
  "not onboarded": "Not Setup",
  timeout: "Timeout",
  "in progress": "In Progress",
  succeeded: "Active",
  failed: "Error",
  inactive: "Inactive",
  queued: "Queued",
  unknown: "Unknown",
};

const ONBOARDING_VARIANTS = {
  "not onboarded": "default",
  timeout: "failed",
  "in progress": "info",
  succeeded: "success",
  failed: "failed",
  queued: "pending",
  unknown: "warning",
  inactive: "default",
};

const DEPLOYMENT_STATUSES = {
  DEFAULT: "Not Configured",
  SUCCESS: "Deployed",
  IN_PROGRESS: "In Progress",
  FAILED: "Failed",
  TIMEOUT: "Timeout",
  QUEUED: "Queued",
  UNKNOWN: "Unknown",
};

const DEPLOYMENT_STATUS_MAP = {
  "not deployed": DEPLOYMENT_STATUSES.DEFAULT,
  "in progress": DEPLOYMENT_STATUSES.IN_PROGRESS,
  queued: DEPLOYMENT_STATUSES.QUEUED,
  succeeded: DEPLOYMENT_STATUSES.SUCCESS,
  failed: DEPLOYMENT_STATUSES.FAILED,
  timeout: DEPLOYMENT_STATUSES.TIMEOUT,
  unknown: DEPLOYMENT_STATUSES.UNKNOWN,
};

const DEPLOYMENT_STATUS_PILL_VARIANTS = {
  "not deployed": "default",
  "in progress": "info",
  queued: "pending",
  succeeded: "success",
  failed: "failed",
  timeout: "failed",
  unknown: "warning",
};

const tooltipId = "statusTooltip";

const columnHelper = createColumnHelper();

const buildStatusObject = (data, STATUS_MAP) => {
  const BI_MAPPER = {
    is_success: (boolean) => ({ isBiDeployed: String(boolean) }),
    start_timestamp: (timestamp) => ({
      biDeploymentStart: DateTime.fromMillis(timestamp),
    }),
    finish_timestamp: (timestamp) => ({
      biDeploymentFinish: DateTime.fromMillis(timestamp),
    }),
  };

  const DATA_MAPPER = {
    is_success: (boolean) => ({ isDataDeployed: String(boolean) }),
    start_timestamp: (timestamp) => ({
      dataDeploymentStart: DateTime.fromMillis(timestamp),
    }),
    finish_timestamp: (timestamp) => ({
      dataDeploymentFinish: DateTime.fromMillis(timestamp),
    }),
  };

  const SUMMARY_MAPPER = {
    status: (rawStatus) => ({
      rawStatus: rawStatus || "unknown",
      status: STATUS_MAP[rawStatus || "unknown"],
    }),
    message: (string) => ({ message: string }),
    deployment_number: (number) => ({ deploymentNumber: number }),
    start_timestamp: (timestamp) => ({
      licenseDeploymentStart: DateTime.fromMillis(timestamp),
    }),
    finish_timestamp: (timestamp) => ({
      licenseDeploymentFinish: DateTime.fromMillis(timestamp),
    }),
  };

  const defaultObject = {
    rawStatus: "unknown",
    status: STATUS_MAP["unknown"],
  };
  const biObject = data.bi
    ? Object.keys(data.bi).reduce((object, key) => {
        if (BI_MAPPER[key]) {
          return {
            ...object,
            ...BI_MAPPER[key](data.bi[key]),
          };
        }

        return object;
      }, {})
    : {};
  const dataObject = data.rows
    ? Object.keys(data.rows).reduce((object, key) => {
        if (DATA_MAPPER[key]) {
          return {
            ...object,
            ...DATA_MAPPER[key](data.rows[key]),
          };
        }

        return object;
      }, {})
    : {};
  const licenseObject = data
    ? Object.keys(data).reduce((object, key) => {
        if (SUMMARY_MAPPER[key]) {
          return {
            ...object,
            ...SUMMARY_MAPPER[key](data[key]),
          };
        }

        return object;
      }, {})
    : {};

  return {
    ...defaultObject,
    ...biObject,
    ...dataObject,
    ...licenseObject,
  };
};

const StatusTooltip = ({ data }) => {
  return (
    <ThemeProvider theme={theme}>
      <div
        style={{
          padding: "8px",
          display: "flex",
          flexDirection: "column",
          gap: "15px",
        }}
      >
        <div>
          <Typography variant="h4" color="white">
            Summary
          </Typography>
          <Typography variant="p" color="white">
            <strong>Status:</strong> {data.status}
          </Typography>
          {data.message && (
            <Typography variant="p" color="white">
              <strong>Message:</strong> {data.message}
            </Typography>
          )}
          <Typography variant="p" color="white">
            <strong>Start Time:</strong>{" "}
            {data.licenseDeploymentStart?.toFormat(dateTimeFormat)}
          </Typography>
          {data.licenseDeploymentFinish && (
            <Typography variant="p" color="white">
              <strong>Duration:</strong>{" "}
              {data.licenseDeploymentFinish
                .diff(data.licenseDeploymentStart)
                .toFormat(durationFormat)}
            </Typography>
          )}
        </div>
        {["succeeded"].includes(data.rawStatus) && (
          <>
            <div>
              <Typography variant="h4" color="white">
                BI
              </Typography>
              <Typography variant="p" color="white">
                <strong>Success:</strong> {data.isBiDeployed}
              </Typography>
              <Typography variant="p" color="white">
                <strong>Start Time:</strong>{" "}
                {data.biDeploymentStart.toFormat(dateTimeFormat)}
              </Typography>
              <Typography variant="p" color="white">
                <strong>Duration:</strong>{" "}
                {data.biDeploymentFinish
                  .diff(data.biDeploymentStart)
                  .toFormat(durationFormat)}
              </Typography>
            </div>
            <div>
              <Typography variant="h4" color="white">
                Data
              </Typography>
              <Typography variant="p" color="white">
                <strong>Success:</strong> {data.isDataDeployed}
              </Typography>
              <Typography variant="p" color="white">
                <strong>Start Time:</strong>{" "}
                {data.dataDeploymentStart.toFormat(dateTimeFormat)}
              </Typography>
              <Typography variant="p" color="white">
                <strong>Duration:</strong>{" "}
                {data.dataDeploymentFinish
                  .diff(data.dataDeploymentStart)
                  .toFormat(durationFormat)}
              </Typography>
            </div>
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

const OnboardingStatusCell = (props) => {
  const { active: orgIsActive, id: organizationId } = props.row.original;

  const fetchOnboardingCall = useAuthenticatedCall((req) =>
    getOrganizationOnboarding({ ...req, organizationId })
  );
  const { isLoading: isOnboardingFetching, data: onboarding } = useQuery({
    queryKey: ["organization-onboarding", organizationId],
    queryFn: fetchOnboardingCall,
    refetchOnMount: "always",
    select: (data) => {
      const formattedData = buildStatusObject(data, ONBOARDING_LABELS);

      return {
        ...formattedData,
        tooltip: renderToStaticMarkup(<StatusTooltip data={formattedData} />),
      };
    },
  });

  if (isOnboardingFetching) {
    return <Skeleton style={{ width: "50px", height: "18px" }} />;
  }
  return (
    <Pill
      text={
        orgIsActive
          ? ONBOARDING_LABELS[onboarding?.rawStatus]
          : ONBOARDING_LABELS["inactive"]
      }
      size="small"
      variant={
        orgIsActive
          ? ONBOARDING_VARIANTS[onboarding?.rawStatus]
          : ONBOARDING_VARIANTS["inactive"]
      }
      tooltipId={tooltipId}
      tooltipStrategy="fixed"
      tooltipContent={
        orgIsActive
          ? ["succeeded", "timeout", "failed"].includes(onboarding?.rawStatus)
            ? onboarding.tooltip
            : null
          : null
      }
    />
  );
};

const DeploymentStatusCell = (props) => {
  const organizationId = props.row.original.id;

  const fetchDeploymentCall = useAuthenticatedCall((req) =>
    getOrganizationDeployment({ ...req, organizationId })
  );
  const { isLoading: isDeploymentFetching, data: licenseDeployment } = useQuery(
    {
      queryKey: ["organization-deployment", organizationId],
      queryFn: fetchDeploymentCall,
      refetchOnMount: "always",
      select: (data) => {
        const formattedData = buildStatusObject(data, DEPLOYMENT_STATUS_MAP);

        return {
          ...formattedData,
          tooltip: renderToStaticMarkup(<StatusTooltip data={formattedData} />),
        };
      },
    }
  );

  if (isDeploymentFetching) {
    return <Skeleton style={{ width: "50px", height: "18px" }} />;
  }

  return (
    <Pill
      text={licenseDeployment?.status}
      size="small"
      variant={DEPLOYMENT_STATUS_PILL_VARIANTS[licenseDeployment?.rawStatus]}
      tooltipId={tooltipId}
      tooltipContent={
        ["succeeded", "timeout", "failed"].includes(
          licenseDeployment?.rawStatus
        )
          ? licenseDeployment?.tooltip
          : null
      }
    />
  );
};

const getOnboardingStatusValue = (status) => {
  switch (status) {
    case "inactive":
      return 1;

    case "not onboarded":
      return 2;

    case "failed":
      return 3;

    case "timeout":
      return 4;

    case "queued":
      return 5;

    case "in progress":
      return 6;

    case "succeeded":
      return 7;

    case "unknown":
    default:
      return 0;
  }
};

const getDeploymentStatusValue = (status) => {
  switch (status) {
    case "not deployed":
      return 1;

    case "failed":
      return 2;

    case "timeout":
      return 3;

    case "queued":
      return 4;

    case "in progress":
      return 5;

    case "succeeded":
      return 6;

    case "unknown":
    default:
      return 0;
  }
};

const staticColumns = [
  columnHelper.accessor("name", {
    header: "Name",
    sortingFn: "textCaseSensitive",
    minSize: 250,
    customStyles: {
      flex: "1 1 250px",
      display: "flex",
      justifyContent: "flex-start",
    },
    cell: (props) => {
      const orgId = props.row.original.id;
      const populiOrg = props.row.original.id === "populi";

      return (
        <TextWithIconTableCell
          position="left"
          icon={populiOrg ? faBuildingUser : faBuilding}
          primaryColor="#D68712"
        >
          <Link
            to={`/admin/org-management/${orgId}`}
            style={{
              fontSize: "14px",
              flexGrow: 1,
              overflowX: "clip",
              textOverflow: "ellipsis",
            }}
          >
            <SearchableTableCell
              searchConfig={props.table.options.meta.searchConfig}
              columnId={props.column.id}
              placeholder="Missing name"
            >
              {props.getValue()}
            </SearchableTableCell>
          </Link>
        </TextWithIconTableCell>
      );
    },
  }),
  columnHelper.accessor("id", {
    header: "ID",
    minSize: 125,
    customStyles: {
      flex: "1 1 125px",
      display: "flex",
      justifyContent: "flex-start",
    },
    cell: SearchableTableCell,
  }),
  columnHelper.accessor((row) => row.domain?.[0], {
    id: "domain",
    header: "Domain",
    minSize: 150,
    customStyles: {
      flex: "1 1 150px",
      display: "flex",
      justifyContent: "flex-start",
    },
    cell: SearchableTableCell,
  }),
  columnHelper.accessor((row) => row.licenses?.user_limit || 0, {
    id: "userLimit",
    header: "User Limit",
    size: 100,
    customStyles: {
      flex: "1 1 100px",
      display: "flex",
      justifyContent: "flex-start",
    },
    sortDescFirst: false,
    cell: (props) => {
      const { licenses, userCount } = props.row.original;
      const assigned = userCount.total;
      const available = licenses?.user_limit || 0;

      if (available === 0) {
        return "—";
      }

      return `${assigned} / ${available}`;
    },
  }),
  columnHelper.accessor(
    (row) => row.licenses?.tableau_user_licenses?.explorer_count || 0,
    {
      id: "biExplorer",
      header: "BI Explorer",
      size: 100,
      customStyles: {
        flex: "1 1 100px",
        display: "flex",
        justifyContent: "flex-start",
      },
      sortDescFirst: false,
      cell: (props) => {
        const { licenses, userCount } = props.row.original;
        const assigned = userCount.explorer;
        const available = licenses?.tableau_user_licenses?.explorer_count || 0;

        if (available === 0) {
          return "—";
        }

        return `${assigned} / ${available}`;
      },
    }
  ),
  columnHelper.accessor(
    (row) => row.licenses?.tableau_user_licenses?.viewer_count || 0,
    {
      id: "biViewer",
      header: "BI Viewer",
      size: 100,
      customStyles: {
        flex: "1 1 100px",
        display: "flex",
        justifyContent: "flex-start",
      },
      sortDescFirst: false,
      cell: (props) => {
        const { licenses, userCount } = props.row.original;
        const assigned = userCount.viewer;
        const available = licenses?.tableau_user_licenses?.viewer_count || 0;

        if (available === 0) {
          return "—";
        }

        return `${assigned} / ${available}`;
      },
    }
  ),
  columnHelper.accessor(
    (row) => (row.active ? row.onboarding?.status : "inactive"),
    {
      id: "onboarding",
      header: "Onboarding",
      size: 150,
      customStyles: {
        flex: "1 1 150px",
        display: "flex",
        justifyContent: "flex-start",
      },
      cell: OnboardingStatusCell,
      sortingFn: (rowA, rowB, columnId) => {
        const statusValueA = getOnboardingStatusValue(
          rowA.original.active ? rowA.original.onboarding?.status : "inactive"
        );
        const statusValueB = getOnboardingStatusValue(
          rowB.original.active ? rowB.original.onboarding?.status : "inactive"
        );

        if (statusValueA < statusValueB) {
          return -1;
        }

        if (statusValueA > statusValueB) {
          return 1;
        }

        return 0;
      },
    }
  ),
  columnHelper.accessor("deployment", {
    id: "deployment",
    header: "Deployment",
    minSize: 160,
    customStyles: {
      flex: "1 1 160px",
      display: "flex",
      justifyContent: "flex-start",
    },
    cell: DeploymentStatusCell,
    sortDescFirst: false,
    sortingFn: (rowA, rowB, columnId) => {
      const statusValueA = getDeploymentStatusValue(
        rowA.original.licenses?.deployment?.status
      );
      const statusValueB = getDeploymentStatusValue(
        rowB.original.licenses?.deployment?.status
      );

      if (statusValueA < statusValueB) {
        return -1;
      }

      if (statusValueA > statusValueB) {
        return 1;
      }

      return 0;
    },
  }),
  columnHelper.accessor(
    (row) => row.licenses?.deployment?.last_successful_timestamp || 0,
    {
      id: "deploymentDate",
      header: "Last Deployment",
      size: 175,
      cell: (info) => {
        const value = info.getValue();

        if (value === 0) {
          return "—";
        }

        return DateTime.fromMillis(value).toFormat("ff");
      },
      sortDescFirst: false,
      sortingFn: (rowA, rowB, columnId) => {
        const dateValueA = rowA.getValue(columnId);
        const dateValueB = rowB.getValue(columnId);

        if (dateValueA < dateValueB) {
          return -1;
        }

        if (dateValueA > dateValueB) {
          return 1;
        }

        return 0;
      },
    }
  ),
];

const OrganizationTable = (props) => {
  const navigate = useNavigate();
  const { data, isRefreshing, searchFilter, isLoading, canUpdate } = props;

  const [orgToDeactivate, setOrgToDeactivate] = useState(null);
  const [orgToActivate, setOrgToActivate] = useState(null);

  const dynamicColumns = [
    columnHelper.display({
      id: "actions",
      size: 65,
      customStyles: {
        flex: "0 1 65px",
        display: "flex",
        justifyContent: "flex-end",
      },
      cell: ({ row }) => {
        const openRowMenuId = row.original.id;
        const openRowMenuOrg = data.find((org) => org.id === openRowMenuId);

        return (
          <Popover
            placement="bottom-end"
            offset={3}
            actions={[
              {
                name: "Edit",
                onClick: () =>
                  navigate(`/admin/org-management/${openRowMenuId}/update`, {
                    state: { from: "organizations" },
                  }),
              },
              {
                name: "Manage Users",
                onClick: () =>
                  navigate(`/admin/org-management/${openRowMenuId}`),
              },
              openRowMenuId !== "populi"
                ? openRowMenuOrg?.active
                  ? {
                      name: "Deactivate",
                      onClick: () => setOrgToDeactivate(openRowMenuOrg),
                    }
                  : {
                      name: "Activate",
                      onClick: () => setOrgToActivate(openRowMenuOrg),
                    }
                : null,
            ].filter((v) => !!v)}
            TriggerComponent={(props) => {
              const { setRef, ...triggerProps } = props;

              return (
                <div
                  style={{
                    width: "50px",
                  }}
                >
                  <FontAwesomeIcon
                    icon={faEllipsis}
                    style={{
                      width: "100%",
                      fontSize: "19px",
                      cursor: "pointer",
                    }}
                    ref={setRef}
                    {...triggerProps}
                  />
                </div>
              );
            }}
          />
        );
      },
    }),
  ];

  const columnVisibility = useMemo(
    () => ({
      actions: canUpdate,
    }),
    [canUpdate]
  );

  const { currentOrgPageNumber } = useNavigation();

  return (
    <>
      <Table
        data={data}
        columns={staticColumns.concat(dynamicColumns)}
        isRefreshing={isRefreshing}
        searchConfig={searchFilter}
        isLoading={isLoading}
        columnVisibility={columnVisibility}
        pageKey="Org"
        defaultState={{
          pagination: {
            pageSize: 20,
            pageIndex: currentOrgPageNumber,
          },
        }}
      />

      {orgToDeactivate ? (
        <DeactivateOrganizationModal
          organization={orgToDeactivate}
          close={() => {
            setOrgToDeactivate(null);
          }}
        />
      ) : null}

      {orgToActivate ? (
        <ActivateOrganizationModal
          organization={orgToActivate}
          close={() => {
            setOrgToActivate(null);
          }}
        />
      ) : null}
      <Tooltip id={tooltipId} style={{ maxWidth: "450px", zIndex: "10000" }} />
    </>
  );
};

export default OrganizationTable;
