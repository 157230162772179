import { Navigate, useLocation } from "react-router-dom";

import useAuthStateChanged from "core/hooks/useAuthStateChanged";
import useAuth from "core/hooks/useAuth";
import Loader from "core/components/Loader";
import Page from "core/components/Page";
import Link from "core/components/Link";
import LoginForm from "modules/auth/LoginForm";
import useFlash from "core/hooks/useFlash";
import Success from "core/components/Success";
import { useState } from "react";
import ButtonLink from "core/components/ButtonLink";
import { Grid, GridColumn, Line } from "core/styles";
import CopyButton from "core/components/CopyButton";
import Typography from "core/components/Typography";

const defaultValues = { email: "", password: "" };

const Login = () => {
  const { login } = useAuth();
  const location = useLocation();
  const { initializing, user } = useAuthStateChanged();
  const { success } = useFlash();
  const [error, setError] = useState("");

  if (initializing) {
    return <Loader />;
  }

  if (user) {
    if (user.isPasswordExpiring) {
      return <Navigate to="/expiring-password" replace />;
    } else {
      return <Navigate to={location.state?.from?.pathname || "/"} replace />;
    }
  }

  const handleSubmit = async (values) => {
    setError("");

    try {
      await login(values);
    } catch (error) {
      setError(error);
    }
  };

  return (
    <Page>
      <Grid columns="350px" justify="center" lift="15vh">
        <GridColumn align="center">
          <img
            src="/dh-logo.svg"
            alt="logo"
            height="66px"
            style={{ marginBottom: error ? "0px" : "20px", color: "#123123" }}
          />

          <Typography variant="h3">Atlas Platform</Typography>

          <Typography variant="h5">
            Formerly Populi (Claims and Consumer Analytics)
          </Typography>

          <Success>{success}</Success>

          <LoginForm
            onSubmit={handleSubmit}
            defaultValues={defaultValues}
            error={error}
          />

          <Link
            to="/forgot-password"
            style={{ display: "block", marginBottom: "7px" }}
          >
            Reset Password
          </Link>

          <div
            style={{
              position: "relative",
              display: "flex",
              alignItems: "baseline",
            }}
          >
            <Typography
              variant="p"
              title="Having trouble? Contact support@populi.ai"
              style={{ margin: 0 }}
            >
              Having trouble? Contact{" "}
              <a href="mailto:support@populi.ai">support@populi.ai</a>
            </Typography>
            <CopyButton value="support@populi.ai" hoverTitle="Copy Link" />
          </div>

          <Line />

          <Typography variant="p" mb="md">
            Not a customer? Contact us to learn more.
          </Typography>

          <ButtonLink
            as="a"
            variant="outlined"
            href="https://populi.ai/request-demo/"
          >
            Learn More
          </ButtonLink>
        </GridColumn>
      </Grid>
    </Page>
  );
};

export default Login;
